<template>
    <Row :gutter="20"> 
        <Col span='8' style="border-right: 1px solid #eee;">
            <teamEdit :sport_id="sport_id" :team_id="team_id" ></teamEdit>
        </Col>
        <Col span='16' style="border-left: 1px solid #eee;">
            <!-- <seasonList :sport_id="sport_id" :team_id="team_id" ></seasonList> -->
        </Col>
    </Row>
</template>
<script>

import teamEdit from './edit.vue';
// import seasonList from '../season/list.vue';

export default {
    name: "team-detail",
    props: {
        sport_id: Number,   // 运动id
        team_id: Number,   // 联赛id
    },
    components: {
        teamEdit,
        // seasonList
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>